import { useState } from 'react';
import '../styles/Info.css'

function Info(profileData) {
    
    const [profileInfo, setProfileInfo] = useState(false);
    
    const listExperiences = profileData.userInfo.experience.map(
        (element) => {
            return (
                <ul key={profileData.userInfo.experience.indexOf(element)}>
                    <li>
                        {element.title} @ {element.local}
                    </li>
                </ul>
            )
        }
    )

    const listSkills = profileData.userInfo.skills.map(
        (element) => {
            return (
                <ul key={profileData.userInfo.skills.indexOf(element)}>
                    <li>
                        {element.name}
                    </li>
                </ul>
            )
        }
    )

    const listAcademic = profileData.userInfo.academic.map(
        (element) => {
            return (
                <ul key={profileData.userInfo.academic.indexOf(element)}>
                    <li>
                        {element.course} @ {element.institution} ({element.start_date.year} - {element.end_date.year})
                    </li>
                </ul>
            )
        }
    )

    const toggleInfo = () => {
        setProfileInfo(current => !current);
    }

    return (
        <div className="infoDiv">
            <h1>{profileData.userInfo.name}</h1>
            <h2>{profileData.userInfo.activity_linkedin}</h2>
            <h4>{profileData.userInfo.city ? profileData.userInfo.city + ", " : ""}
                {profileData.userInfo.state ? profileData.userInfo.state + ", " : ""}
                {profileData.userInfo.city ? profileData.userInfo.country: ""}
            </h4>
            <p>{profileData.userInfo.about_me}</p>
            {profileInfo && <>
                <p>Experiências:</p>
                {listExperiences} 
            </>}
            {profileInfo && <>
                <p>Habilidades:</p>
                {listSkills}
            </>}
            {profileInfo && <>
                <p>Formações:</p>
                {listAcademic}
            </>}
            <p className="textButton" onClick={toggleInfo}>{profileInfo ? "Menos informações" : "Mais informações"}</p>
        </div>
    )
}

export default Info;