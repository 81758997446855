import {AiFillLike, AiFillDislike} from "react-icons/ai";

function RecommendList(props) {

    const handleDislike = (e) => {
        if (props.like.includes(e)) {
            props.setLike([...props.like.filter(i => i !== e)])
        }
        props.setDislike([...props.dislike, e]);
    }

    const handleLike = (e) => {
        if (props.dislike.includes(e)) {
            props.setDislike([...props.dislike.filter(i => i !== e)])
        }
        props.setLike([...props.like, e]);
    }

    const listOffersOne = props.array.slice(0, 10).map(
        (element) => {

            return (
                <div key={element.id} 
                    className={"offerDiv flex-item-left"}>
                    <p className="offerTitle">
                        {element.title} @ {element.employer}
                    </p>
                    <div className="offerDescription">
                        <p>{element.description}</p>
                    </div>
                    <div className="feedbackButtons centro">
                        <button className={props.like.includes(element) ?
                            "inputBtn verde" : "inputBtn"} 
                            onClick={() => handleLike(element)}>
                            <AiFillLike/>
                        </button>
                        <button className={props.dislike.includes(element) ?
                            "inputBtn vermelho" : "inputBtn"}
                            onClick={() => handleDislike(element)}>
                            <AiFillDislike/>
                        </button>
                    </div>
                </div>
            )
        }
    )
    
    const listOffersTwo = props.array.slice(10, 20).map(
        (element) => {

            return (
                <div key={element.id} 
                    className={"offerDiv flex-item-right"}>
                    <p className="offerTitle">
                        {element.title} @ {element.employer}
                    </p>
                    <div className="offerDescription">
                        <p>{element.description}</p>
                    </div>
                    <div className="feedbackButtons centro">
                        <button className={props.like.includes(element) ?
                            "inputBtn verde" : "inputBtn"} 
                            onClick={() => handleLike(element)}>
                            <AiFillLike/>
                        </button>
                        <button className={props.dislike.includes(element) ?
                            "inputBtn vermelho" : "inputBtn"}
                            onClick={() => handleDislike(element)}>
                            <AiFillDislike/>
                        </button>
                    </div>
                </div>
            )
        }
    )

    return (
        <div className="recommendationDiv">
            <div className="subDiv">{listOffersOne}</div>
            <div className="subDiv">{listOffersTwo}</div>
        </div>
    )

}

export default RecommendList;