import { useState, useEffect } from 'react';
import '../styles/List.css';
import './Info'
import axios from 'axios'
import Info from './Info';
import RecommendList from './RecommendList';

function List(props) {

    const [goodSelection, setGoodSelection] = useState([]);
    const [badSelection, setBadSelection] = useState([]);
    const [feedback, setFeedback] = useState(false);
    const [message, setMessage] = useState("");
    const [next, setNext] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        console.log("positivo: " + goodSelection.length)
        console.log("negativo: " + badSelection.length)
        if (((goodSelection.length + badSelection.length === 20) && (!next)) || 
            (goodSelection.length + badSelection.length === 40))
            setDisabled(false);
    }, [goodSelection, badSelection, next])

    const toggleNext = () => {
        setNext(true);
        setDisabled(true);
        window.scrollTo(0, 0);
    }

    const togglePrevious = () => {
        setNext(false);
        setDisabled(false);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if (goodSelection.length) {
            axios.post("/api/result", {
                good_selection: goodSelection,
                bad_selection: badSelection
            }).then(response => {
                setMessage(response.data);
                setFeedback(true);
            })
        }
    }

    return (
        <div className="inputDiv">
            {!feedback ?
                <>
                    <Info userInfo={props.userInfo}/>
                    {!next ? <>
                        <h2>Por favor, avalie as seguinte vagas:</h2>
                        <RecommendList array={props.array.good}
                            like={goodSelection}
                            setLike={setGoodSelection}
                            dislike={badSelection}
                            setDislike={setBadSelection}
                        />
                        <button className={disabled  
                                            ? "inputBtn centro desativado"
                                            : "inputBtn centro"}
                            onClick={toggleNext}
                            disabled={disabled}>Próximo</button>
                    </> : <>
                    <h2>Por favor, avalie as seguintes vagas:</h2>
                        <RecommendList array={props.array.bad}
                            like={goodSelection}
                            setLike={setGoodSelection}
                            dislike={badSelection}
                            setDislike={setBadSelection}
                        />
                        <div className="buttonsDiv">
                            <button className={disabled  
                                                ? "inputBtn centro desativado"
                                                : "inputBtn centro"}
                                onClick={handleSubmit}
                                disabled={disabled}>Enviar</button>
                            <button className="inputBtn centro" onClick={togglePrevious}>Voltar</button>
                        </div>
                    </>}
                </> : <>
                    <div className="feedbackDiv">
                        <h1>{message}</h1>
                    </div>
                </>}
        </div>
    )
}

export default List;