import { useState } from 'react';
import '../styles/Form.css';
import axios from 'axios';

function Form(props) {

  const [url, setUrl] = useState("");

  async function handleSubmit(event) {

    event.preventDefault();

    if (url) {
      props.setLoading(true);
      axios.post("/api/url", {
        url: url
      }).then(response => {
        console.log(response);
        props.setProfile(response.data.user);
        props.setRecommendations(response.data.recommendations);
        setUrl("");
      }).catch(error => {
        console.log(error);
        props.setError(true);
        props.setLoading(false);
      })
    }
  }

  return (
    <div className="inputDiv">
      <h1 className="title">Recomendador de Vagas</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="profile_url">Insira a URL do seu perfil no LinkedIn:</label>
        <br />
        <input className="urlInput" name="text" id="profile_url" onInput={event => setUrl(event.target.value)} />
        <input className="inputBtn" type="submit" value="Enviar perfil" />
      </form>
      <p className="bold">Instruções: </p>
      <ul className="lista">
        <li>
          Não atualize a página durante o processo
        </li>
        <li>
          O processo demora um pouco, por favor aguarde
        </li>
      </ul>
    </div>
  )
}

export default Form;