function ErrorPage(props) {
    return (
        <div className="feedbackDiv">
            <h1>Erro</h1>
            <h2>{props.errorMessage}</h2>
            <h3>Tente novamente</h3>
            <button className="inputBtn centro" onClick={() => props.setError(false)}>Voltar</button>
        </div>
    )
}

export default ErrorPage;