import logo from '../img/senai.png';
import '../styles/Footer.css';

function Footer () {
    return (
        <div className="footer">
            <div className="div-image">
                <img
                    className="footer-img"
                    src="https://www.c3sl.ufpr.br/wp-content/uploads/2021/10/logotipo-ideal-para-fundos-claros.png"
                    alt="c3sl-logo"
                />
            </div>
            <div className="div-image">
                <img className="footer-img" src={logo} alt="senai-logo"/>
            </div>
        </div>
    );
}

export default Footer;