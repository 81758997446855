import { useState, useEffect } from 'react';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import Form from './components/Form';
import './App.css';
import List from './components/List';
import ErrorPage from './components/ErrorPage';

function App() {

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(false);
    if (recommendations.length !== 0) {
      setData(true);
    }
  }, [recommendations])

  function handleClick() {
    setData(false);
    setRecommendations([]);
  }

  function ListJobs () {
    return (
      <List array={recommendations} 
          userInfo={profile}
          handleClick={handleClick}/>
    )
  }

  return (
    <div className="App">
      {loading ? (
          <div className="loadingDiv">
            <LoadingSpinner/>
          </div>
        ) : (
          data ? (
            <ListJobs/>
          ) : (
            error ? 
              <ErrorPage
                setError={setError}
                errorMessage={errorMessage}
              /> :
              <Form
                setLoading={setLoading}
                setProfile={setProfile}
                setRecommendations={setRecommendations}
                setError={setError}
                setErrorMessage={setErrorMessage}
              />
          )
        )}
      <Footer/>
    </div>
  );
}

export default App;
